<template>
    <LiefengContent>
        <template v-slot:title>
            {{ discuss ? "评议管理" : "评价审核" }}
        </template>
        <template v-slot:toolsbarRight>
            <RadioGroup v-if="discuss" v-model="activity" type="button" button-style="solid">
                <Radio :label="0">屏蔽</Radio>
                <Radio :label="1">显示</Radio>
            </RadioGroup>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="columns"
                :tableData="dataList"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="maxCount"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <LiefengModal @input="inputModal" :value="showModal" :title="title" width="400px" height="720px">
                <template slot="contentarea">
                    <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="720px" frameborder="0" style="display: block"></iframe>
                </template>
            </LiefengModal>
            <Preview ref="preview"></Preview>
        </template>
    </LiefengContent>
    <!-- <div class="agendaroom-comment">
        <div>
            <CompTable isSelector :table-api="tableApi" ref="comp_table" :title="discuss ? '评议管理' : '评价审核'" :columns="columns" :dataList="dataList">
                <template #operate>
                    <RadioGroup v-if="discuss" v-model="activity" type="button" button-style="solid">
                        <Radio :label="0">屏蔽</Radio>
                        <Radio :label="1">显示</Radio>
                    </RadioGroup>
                </template>
            </CompTable>
        </div>
        <LiefengModal @input="inputModal" :value="showModal" :title="title" width="400px" height="720px">
            <template slot="contentarea">
                <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="720px" frameborder="0" style="display: block"></iframe>
            </template>
        </LiefengModal>
        <Preview ref="preview"></Preview>
    </div> -->
</template>

<script>
import CompTable from "../jointly/components/comp-table"
import Preview from "../comment/components/Preview.vue"
import CompModal from "../residentdatabase/components/CompModal.vue"
import Route from "../communitymobilization/entity/Route"
import Request from "../jointly/utils/request"
import proxy from "@/api/proxy"
import LiefengModal from "@/components/LiefengModal"
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: { CompTable, Preview, CompModal, LiefengModal, LiefengContent, LiefengTable },

    data() {
        return {
            tableApi: null,
            columns: [
                {
                    title: "状态",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "RadioGroup",
                            {
                                props: {
                                    value: params.row.status == 2 ? "1" : "0",
                                },
                                on: {
                                    "on-change": () => {
                                        Request.post("/gateway/syinfopublish/api/app/infoComments/updateDisplayStatus", {
                                            id: params.row.id,
                                        }).then(() => {
                                            this.$Message.success("修改成功")
                                            // 刷新列表
                                            this.$refs.comp_table.refresh()
                                        })
                                    },
                                },
                            },
                            [
                                h(
                                    "Radio",
                                    {
                                        attrs: {
                                            label: "0",
                                        },
                                    },
                                    "屏蔽"
                                ),
                                h(
                                    "Radio",
                                    {
                                        attrs: {
                                            label: "1",
                                        },
                                    },
                                    "公开"
                                ),
                            ]
                        )
                        // const date = new Date(params.row.gmtCreate)
                        // return this.$core.formatDate(date, (date.getFullYear() !== new Date().getFullYear() ? "yyyy年" : "") + "MM月dd日")
                    },
                },
                {
                    title: "手机号",
                    align: "center",
                    key:'mobile'
                },
                {
                    title: "内容",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        const files = []

                        const images = params.row.photo?.split(",")

                        images?.forEach(item => {
                            if (!item) return
                            files.push(
                                h("div", {
                                    style: {
                                        width: "300px",
                                        height: "50px",
                                        margin: "3px",
                                        background: `url(${item})`,
                                        "background-size": "cover",
                                        "background-position": "center",
                                        "border-radius": "5px",
                                        cursor: "pointer",
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.preview.display(item, images)
                                        },
                                    },
                                })
                            )
                        })

                        return h(
                            "div",
                            {
                                style: {
                                    margin: "10px 0",
                                },
                            },
                            [
                                h(
                                    "p",
                                    {
                                        style: {
                                            "text-align": "center",
                                        },
                                    },
                                    params.row.content || "-"
                                ),
                                ...(files.length > 0
                                    ? [
                                          h(
                                              "div",
                                              {
                                                  style: {
                                                      display: "flex",
                                                      "justify-content": "center",
                                                      "flex-wrap": "wrap",
                                                      "margin-top": "5px",
                                                  },
                                              },
                                              files
                                          ),
                                      ]
                                    : []),
                            ]
                        )
                    },
                },
                {
                    title: "日期",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtModified ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "来源",
                    key: "title",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "#2d8cf0",
                                    cursor: "pointer",
                                    "text-decoration": "underline",
                                },
                                on: {
                                    click: () => {
                                        this.showModal = true
                                        this.title = "详情"
                                        this.iframeUrl = `${proxy["/custom"].target}/#/apps/suiyue/pages/information/sharingdetails/details?code=${params.row.infoId}&functionType=${
                                            params.row.functionType
                                        }&token=${window.sessionStorage.getItem("accessToken")}`
                                    },
                                },
                            },
                            params.row.title
                        )
                    },
                },
                {
                    title: "栏目",
                    key: "name",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "#2d8cf0",
                                    cursor: "pointer",
                                    "text-decoration": "underline",
                                },
                                on: {
                                    click: () => {
                                        if (!params.row.url || params.row.url == "") return
                                        console.log(`/page#${params.row.url}`)
                                        this.$core.openLayerFrame({
                                            type: 2,
                                            title: params.row.name,
                                            content: `/page#${params.row.url}`,
                                            area: ["100%", "100%"],
                                        })
                                    },
                                },
                            },
                            params.row.name
                        )
                    },
                },

                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        const arr = []
                        arr.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDelete(params.row.id)
                                        },
                                    },
                                },
                                "删除"
                            )
                        )
                        arr.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.title = "畅联通"
                                            this.showModal = true
                                            this.onOpenMessageDetail(params.row)
                                        },
                                    },
                                },
                                "发消息"
                            )
                        )

                        return h("div", arr)
                    },
                },
            ],
            // 评议
            discuss: false,
            isOrg: false,
            activity: 0,

            iframeUrl: null,
            showModal: false,

            dataList: [],
            itemItem: {},
            title: "",
            page: 1,
            pageSize: 20,
            maxCount: 0,
            loading: false,
        }
    },

    watch: {
        activity(v) {
            this.page = 1
            this.getTableData()
        },
    },

    created() {
        this.discuss = this.$core.getUrlParam("type") === "discuss"
        this.isOrg = this.$core.getUrlParam("isOrg") === "1"
        // this.setTableUrl()
        this.getTableData()
    },

    methods: {
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getTableData()
        },
        onDelete(id) {
            this.$Modal.confirm({
                title: "提示",
                content: "您正在删除数据，是否继续？",
                onOk: () => {
                    this.$post("/gateway/syinfopublish/api/pc/infoComments/deleteById", {
                        id,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            // 刷新表格数据
                            this.getTableData()
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        getTableData() {
            this.$get(this.setTableUrl()).then(res => {
                this.dataList = res.dataList.map(item => {
                    if (item.title && item.title != "" && item.title.indexOf(",") > -1) {
                        let list = []
                        list = item.title.split(",")
                        item.title = list[0]
                        item.columnCode = list[1] || null
                        item.selfOrgCode = list[2] || null
                        item.functionType = list[3] || null
                    }
                    this.getColumnName(parent.vue.menuList, item.columnCode)
                    return {
                        ...item,
                        ...this.itemItem,
                    }
                })
                this.page = res.currentPage
                this.pageSize = res.pageSize
                this.maxCount = res.maxCount
            })
        },
        getColumnName(data, columnCode) {
            if (!data || !data.length) return
            if (!columnCode || columnCode == "") return
            data.map(item => {
                if (item.columnCode && item.columnCode != "") {
                    if (item.columnCode == columnCode) {
                        this.itemItem = {
                            name: item.name,
                            menuId: item.menuCode,
                            url: item.url,
                        }
                    }
                } else {
                    this.getColumnName(item.subMenus, columnCode)
                }
            })
        },
        inputModal(status) {
            this.showModal = status
        },
        onOpenMessageDetail(item) {
            try {
                const loading = this.$Message.loading({
                    content: "连接中...",
                    duration: 0,
                })

                this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                    onlyUserId: item.custGlobalId,
                })
                    .then(res => {
                        if (res.code !== "200" || !res.data) {
                            return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                        }
                        this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.userName}&businessType=1&businessCode=2&custGlobalId=${
                            item.custGlobalId
                        }&token=${window.sessionStorage.getItem("accessToken")}`
                    })
                    .finally(() => {
                        loading()
                    })
            } catch (err) {
                console.log("err", err)
            }
        },
        setTableUrl() {
            var url = `/gateway/syinfopublish/api/pc/infoComments/listComments?page=${this.page}&pageSize=${this.pageSize}&infoType=INFORMATION&orgCode=&client=PC`

            if (this.discuss) {
                url += `&status=${this.activity === 0 ? 1 : 2}&excludeOneself=2`
            } else {
                url += `&status=1&excludeOneself=2`
            }

            if (this.isOrg) {
                url += `&orgCodeSelf=${window.sessionStorage.getItem("orgCode")}`
            }
            this.tableApi = url
            return url
        },
    },
}
</script>
<style lang="less">
.agendaroom-comment {
    .menu-bar-box {
        height: 45px;
        margin: 10px 10px 20px 10px;

        .menu-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #f3f3f3;
            background: #fff;
            border-radius: 5px;
            display: flex;

            .slider {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                transition: all 0.3s ease;

                &::after {
                    content: "";
                    background: #2faaf7;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    bottom: 5px;
                    right: 5px;
                    border-radius: 3px;
                }
            }

            > .item-box {
                position: relative;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                padding: 0 20px;
            }

            > .activity {
                color: #fff;
            }
        }
    }
}

/deep/ .ivu-modal-body {
    height: 700px;
}
/deep/.modal_contentarea {
    /deep/ div {
        height: 100%;
    }
}
</style>
